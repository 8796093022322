<template>
  <div>
    <template>
      <div v-if="attendancesIsEditMode">
        <Heading heading="h4" :content=" $t('newCourses.Attendance')" class="mb-4">
          <template v-if="roleIsTeacherOrAdmin">
            <el-button
              slot="button"
              type="primary"
              size="mini"
              @click="closeAttendancesEditMode"
            >
              <i class="fas fa-chevron-left"></i>
              {{ $t("button.back") }}
            </el-button>
          </template>
        </Heading>
        <EditAttendancesExplain v-if="roleIsTeacherOrAdmin" />
        <hr />
      </div>
      <div v-else>
        <div class="d-flex mb-4">
          <div>
            <Heading heading="h4" :content=" $t('newCourses.Attendance')"></Heading>
          </div>
          <div class="d-flex">
            <template v-if="roleIsTeacherOrAdmin">
              <AddLessonLogForm @getClass="getSessions" />
              <div class="ml-2">
                <el-tooltip
                  content="編輯出席表"
                  placement="top"
                  popper-class="tooltipColor"
                >
                  <el-button
                    type="primary"
                    size="mini"
                    @click="openAttendancesEditMode"
                  >
                    <i class="fas fa-edit" />
                    Edit
                  </el-button>
                </el-tooltip>
              </div>
            </template>
          </div>
        </div>
        <hr />
      </div>
      <el-card>
        <div style="height: 500px; overflow: auto;">
          <EditStudentsAttendances
            v-if="attendancesIsEditMode"
            :classId="$route.params.classId"
            :lessonDates="lessonDates"
            :studentsAttendances="studentsAttendances"
            @setStudentLessonLogAttendances="
              studentLessonLogAttendance =>
                $store.commit(
                  'studentAttendance/setStudentLessonLogAttendance',
                  studentLessonLogAttendance
                )
            "
            @updateStudentAttendance="
              studentAttendance =>
                $store.dispatch(
                  'studentAttendance/updateStudentAttendance',
                  studentAttendance
                )
            "
          />
          <StudentsAttendances
            v-else
            :classId="$route.params.classId"
            :lessonDates="lessonDates"
            :studentsAttendances="studentsAttendances"
            :displayAttendancesStatusByText="true"
            :disableLinkToTeachingLog="roleIsTeacherOrAdmin"
            :displayStudentUserIds="displayStudentUserIds"
          />
        </div>
      </el-card>
    </template>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import { user } from "@ivy-way/material";
import moment from "moment";
import { helper } from "@ivy-way/material";
import roleMixin from "@/mixins/role";
import profileTest from "@/mixins/profileTest";
import courseApi from "@/apis/course";
import lessonLogApi from "@/apis/lessonLog";

import AddLessonLogForm from "@/views/attendance/AddLessonLogForm";
import StudentsAttendances from "@/components/attendance/StudentsAttendances";
import EditStudentsAttendances from "@/components/attendance/EditStudentsAttendances";
import EditAttendancesExplain from "@/components/attendance/EditAttendancesExplain";

export default {
  metaInfo() {
    return {
      title: "Info"
    };
  },

  components: {
    AddLessonLogForm,
    EditStudentsAttendances,
    EditAttendancesExplain,
    StudentsAttendances
  },

  mixins: [roleMixin, profileTest],
  props: [],
  data() {
    return {
      attendancesIsEditMode: false
    };
  },
  computed: {
    ...mapState("user", ["profile", "token"]),
    user() {
      return user;
    },
    helper() {
      return helper;
    },
    roleIsTeacherOrAdmin() {
      return this.isRoleAdmin() || this.isRoleTeacher();
    },
    studentsAttendances() {
      let studentsAttendances = this.$store.getters["studentAttendance/studentsAttendances"];

      if (this.displayStudentUserIds) {
        return studentsAttendances.filter(
          (student) =>
            this.displayStudentUserIds.includes(student.student.id)
        );
      } else {
        return studentsAttendances;
      }
      // return ;
    },
    lessonDates() {
      return this.$store.getters["studentAttendance/lessonDates"];
    },
    displayStudentUserIds() {
      if (this.roleIsTeacherOrAdmin) {
        return null;
      } else {
        if (this.isRoleStudent()) {
          return [this.$store.getters["user/getProfile"].id];
        } else if (this.isRoleParent()) {
          return this.$store.getters["user/getProfile"].student_ids ? this.$store.getters["user/getProfile"].student_ids : [];
        } else {
          return [];
        }
      }
    }
  },
  watch: {},

  mounted() {
    this.getSessions();
  },

  methods: {
    async getSessions() {
      await this.fetchStudentAttandances();
    },
    setStudentAttendance(lesson) {
      this.$store.commit("studentAttendance/setClass", lesson);
    },
    async fetchStudentAttandances() {
      const lesson = await lessonLogApi.fetchStudentAttendances({
        session_class_id: this.$route.params.classId
      });
      this.setStudentAttendance(lesson);
    },

    closeAttendancesEditMode() {
      this.attendancesIsEditMode = false;
    },
    openAttendancesEditMode() {
      this.attendancesIsEditMode = true;
    }
  }
};
</script>

<style scoped></style>
