var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[[(_vm.attendancesIsEditMode)?_c('div',[_c('Heading',{staticClass:"mb-4",attrs:{"heading":"h4","content":_vm.$t('newCourses.Attendance')}},[(_vm.roleIsTeacherOrAdmin)?[_c('el-button',{attrs:{"slot":"button","type":"primary","size":"mini"},on:{"click":_vm.closeAttendancesEditMode},slot:"button"},[_c('i',{staticClass:"fas fa-chevron-left"}),_vm._v(" "+_vm._s(_vm.$t("button.back"))+" ")])]:_vm._e()],2),(_vm.roleIsTeacherOrAdmin)?_c('EditAttendancesExplain'):_vm._e(),_c('hr')],1):_c('div',[_c('div',{staticClass:"d-flex mb-4"},[_c('div',[_c('Heading',{attrs:{"heading":"h4","content":_vm.$t('newCourses.Attendance')}})],1),_c('div',{staticClass:"d-flex"},[(_vm.roleIsTeacherOrAdmin)?[_c('AddLessonLogForm',{on:{"getClass":_vm.getSessions}}),_c('div',{staticClass:"ml-2"},[_c('el-tooltip',{attrs:{"content":"編輯出席表","placement":"top","popper-class":"tooltipColor"}},[_c('el-button',{attrs:{"type":"primary","size":"mini"},on:{"click":_vm.openAttendancesEditMode}},[_c('i',{staticClass:"fas fa-edit"}),_vm._v(" Edit ")])],1)],1)]:_vm._e()],2)]),_c('hr')]),_c('el-card',[_c('div',{staticStyle:{"height":"500px","overflow":"auto"}},[(_vm.attendancesIsEditMode)?_c('EditStudentsAttendances',{attrs:{"classId":_vm.$route.params.classId,"lessonDates":_vm.lessonDates,"studentsAttendances":_vm.studentsAttendances},on:{"setStudentLessonLogAttendances":studentLessonLogAttendance =>
              _vm.$store.commit(
                'studentAttendance/setStudentLessonLogAttendance',
                studentLessonLogAttendance
              ),"updateStudentAttendance":studentAttendance =>
              _vm.$store.dispatch(
                'studentAttendance/updateStudentAttendance',
                studentAttendance
              )}}):_c('StudentsAttendances',{attrs:{"classId":_vm.$route.params.classId,"lessonDates":_vm.lessonDates,"studentsAttendances":_vm.studentsAttendances,"displayAttendancesStatusByText":true,"disableLinkToTeachingLog":_vm.roleIsTeacherOrAdmin,"displayStudentUserIds":_vm.displayStudentUserIds}})],1)])]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }